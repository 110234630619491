body {
    font-family: 'Raleway', sans-serif !important;
    font-size: 0.9rem;
}

.video-container {
    position: relative;
    width: 100%;

    /*padding-bottom: 56.25%; !* 16:9 aspect ratio *!*/
    height: 0;
    height: 410px;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.personal-projects li {
    border-bottom: 1px solid #0000001F;
}

.logo {
    position: absolute;
    margin-top: 3rem !important;
    margin-right: 1rem !important;
}

a {
    text-decoration-line: none !important;
}

.footer a {
    color: black;
    text-decoration-color: black;
}